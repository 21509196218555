import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import logo from "../assets/logo.svg";
import Connector from "./Connector";
import {
  LOCK_PATH,
  MINT_PATH,
  PORTFOLIO_PATH,
  REWARDS_PATH,
  STAKE_PATH,
} from "../app/constants/paths";
import useLockerData from "../app/web3/views/use-locker-data";
import useStakedTlx from "../app/web3/views/use-staked-tlx";

interface HeaderProps {
  $sticky: boolean;
}

const StyledHeader = styled.div<HeaderProps>`
  position: ${(props) => (props.$sticky ? "fixed" : "relative")};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 2rem;
  padding: 0 4rem;
  height: 8.4rem;
  z-index: 1;

  @media (max-width: 1100px) {
    height: 7.2rem;
    position: relative;
    margin-bottom: 2rem;
    padding: 0 2rem;
  }
`;

const Logo = styled(Link)``;

const LogoImage = styled.img`
  height: 3.8rem;
`;

const NavItems = styled.div`
  display: grid;
  grid-gap: 2.4rem;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  background: var(--bg-1);
  height: 5rem;
  border-radius: 2.5rem;

  @media (max-width: 1100px) {
    display: none;
  }
`;

interface NavItemProps {
  $active: boolean;
}

const NavItem = styled(Link)<NavItemProps>`
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  font-weight: 400;
  color: ${(props) => (props.$active ? "var(--primary)" : "var(--sub)")};
  margin: 0 1rem;
  padding: 0 1.6rem;
  border-radius: 1.7rem;
  height: 3.4rem;
  background: ${(props) => (props.$active ? "var(--bg-2)" : "transparent")};
  white-space: nowrap;
`;

const Header = () => {
  const location = useLocation();
  const lockerData = useLockerData();
  const stakedTlx = useStakedTlx();

  return (
    <StyledHeader $sticky={location.pathname === "/"}>
      <Logo to="/">
        <LogoImage src={logo} alt="TLX Logo" />
      </Logo>

      <NavItems>
        <NavItem
          to={`/${MINT_PATH}`}
          $active={location.pathname === `/${MINT_PATH}`}
        >
          Mint
        </NavItem>
        <NavItem
          to={`/${PORTFOLIO_PATH}`}
          $active={location.pathname === `/${PORTFOLIO_PATH}`}
        >
          Portfolio
        </NavItem>
        {stakedTlx !== null && !stakedTlx.isZero() && (
          <NavItem
            to={`/${STAKE_PATH}`}
            $active={location.pathname === `/${STAKE_PATH}`}
          >
            Stake
          </NavItem>
        )}
        {lockerData &&
          !lockerData.isShutdown &&
          !lockerData.userLocked.isZero() && (
            <NavItem
              to={`/${LOCK_PATH}`}
              $active={location.pathname === `/${LOCK_PATH}`}
            >
              Lock
            </NavItem>
          )}
        <NavItem
          to={`/${REWARDS_PATH}`}
          $active={location.pathname === `/${REWARDS_PATH}`}
        >
          Rewards
        </NavItem>
      </NavItems>
      <Connector />
    </StyledHeader>
  );
};

export default Header;
