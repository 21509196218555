import { DOCS, FEEDBACK, SNAPSHOT, STATS } from "./urls";

export interface LinkType {
  name: string;
  path: string | null;
  external: boolean;
}

export const LINKS: LinkType[] = [
  {
    name: "Governance",
    path: SNAPSHOT,
    external: true,
  },
  {
    name: "Docs",
    path: DOCS,
    external: true,
  },
  {
    name: "Feedback",
    path: FEEDBACK,
    external: true,
  },
  {
    name: "Stats",
    path: STATS,
    external: true,
  },
  {
    name: "Terms of Service",
    path: "terms-of-service",
    external: false,
  },
  {
    name: "Privacy Policy",
    path: "privacy-policy",
    external: false,
  },
];
